import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import { dateMonthYear } from "@utils/index"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Footer from "@components/Footer/Footer"
import SocialLinks from "@components/Nav/SocialLinks"
import FilterBlue from "@static/icons/filter-blue.svg"
import Spyglass from "@static/icons/spyglass.svg"

export const projectsPageAndProjectsListQuery = graphql`
  query ProjectsPageAndProjectsListQuery($id: Int) {
    strapiProjectspage(strapiId: { eq: $id }) {
      strapiId
      title
      subtitle
      description
      companiesSectionTitle
    }
    allStrapiProject(sort: {fields: [order], order: DESC}) {
      edges {
        node {
          id
          titleshort
          slug
          date
          showdate
          theme
          thumbnailURL
          heroURL
          featured
          order
        }
      }
    }
    allStrapiCompany(sort: {fields: [name], order: ASC})  {
      edges {
        node {
          id
          name
          url
          logoURL
          Type
        }
      }
    }
  }
`

// thumbnail {
//   childImageSharp {
//     # Specify a fluid image and fragment
//     # The default maxWidth is 800 pixels
//     fluid {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }

const ProjectsPage =  ({ data }) => {
  const projectsPage = data.strapiProjectspage
  const projectsList = data.allStrapiProject
  const companiesList = data.allStrapiCompany

  const title = projectsPage.title ? projectsPage.title : ""
  const description = projectsPage.description ? projectsPage.description : ""
  const subtitle = projectsPage.subtitle ? projectsPage.subtitle : ""
  // const companiesSectionTitle = projectsPage.companiesSectionTitle ? projectsPage.companiesSectionTitle : ""

  // console.log('Featured Projects list: ', featuredProjectsList)
  // console.log('Remaining Projects list: ', remainingProjectsList)

  // const featuredSectionTitle = "Featured Work"
  // const companiesSectionTitle = "Companies I've worked with"

  // const imgStyleTemp = {
  //   display: "block",
  //   marginTop: "30px",
  //   marginBottom: "30px"
  // }

  const companyLogoStyle = {
    maxWidth: "250px",
  }

  const companyLogoLinkStyle = {
    display: "inline-block",
  }

  const CompanyBlock = ({ name, url, logoURL }) => (
    <div className="company" key={name}>
      <a href={url} rel="noreferrer" target="_blank" style={companyLogoLinkStyle}>
        <span>{name}</span>
        {logoURL &&
          <img className="logo" src={logoURL} alt={name} style={companyLogoStyle} />
        }
      </a>
    </div>
  )

  const VentureBlock = ({ name, url, logoURL }) => {
    let theme
    if (name === "Brontosaurus Games") {
      theme = "#53b3b1"
    } else if (name === "Arch.to") {
      theme = "#000000"
    } else if (name === "Green Dynasty Architect & Builder") {
      theme = "#006633"
    } else {
      theme = "#00558b"
    }

    return (
      <div className="company" key={name}>
        <AniLink
          to='/ventures'
          title="Ventures"
          paintDrip
          duration={1}
          hex={theme}
          style={companyLogoLinkStyle}
        >
          <img className="logo" src={logoURL} alt={name} style={companyLogoStyle} />
        </AniLink>
      </div>
    )
  }

  let agencies = []
  let clients = []
  let ventures = []

  if (companiesList) {
    companiesList.edges.map(item => {
      const company = item.node
      // console.log("company: ", company)

      switch (company.Type) {
        case 'Agency':
          const agency = (
            <CompanyBlock
              name={company.name}
              url={company.url}
              logoURL={company.logoURL}
            />
          )
          // console.log('agency: ', agency)
          agencies.push(agency)
          break

        case 'Client':
          const client = (
            <CompanyBlock
              name={company.name}
              url={company.url}
              logoURL={company.logoURL}
            />
          )
          // console.log('client: ', client)
          clients.push(client)
          break

        case 'Venture':
          const venture = (
            <VentureBlock
              name={company.name}
              url={company.url}
              logoURL={company.logoURL}
            />
          )
          // console.log('venture: ', venture)
          ventures.push(venture)
          break

        default:
      }
    })
  }

  // console.log("clients: ", clients)
  // console.log("agencies: " + JSON.stringify(agencies))

  return (
    <Layout>
      <main className="grid-content">
        <div className="grid-content-inner">
          <div id="projects" className="grid-container page projects">
            <SEO
              title={title}
              description={description}
            />
            <Spyglass />
            <h1>{title}</h1>
            <p className="lead">{subtitle}</p>

            <section>
              <div className="grid-frame">
                <div className="grid-block large-up-1">
                  {projectsList.edges.map(item => {
                    const project = item.node
                    const projectID = project.id
                    const projectTitleShort = project.titleshort ? project.titleshort : ''
                    const theme = project.theme ? project.theme : '#00558b'
                    const slug = `/projects/${project.slug}`

                    // const categories = project.categories ? project.categories : null
                    // const date = project.date ? project.date : ''
                    // const showdate = project.showdate ? project.showdate : false

                    // console.log('project.thumbnailURL '+ projectTitleShort + ': ' + JSON.stringify(project.thumbnailURL))

                    let thumbnail
                    const altText = projectTitleShort + ' thumbnail'

                    if (project.thumbnailURL) {
                      thumbnail = (
                        <img src={project.thumbnailURL} alt={altText} />
                      )
                    } else {
                      thumbnail = (
                        <img src="https://via.placeholder.com/1380x500/0000FF/808080" alt={altText} />
                      )
                    }

                    return (
                      <div className="grid-block align-center" key={projectID}>
                        {/*{categories.map(category => {
                          return <p>{category.name}</p>
                        })}*/}
                        <AniLink
                          to={slug}
                          title={projectTitleShort}
                          paintDrip
                          duration={1}
                          hex={theme}
                        >
                          {thumbnail}
                          <div className="thumbnail-hover-details">
                            <div className="details-inner">
                              <h3>{projectTitleShort}</h3>
                              {/*{date && showdate &&
                                <h4>{date}</h4>
                              }*/}
                            </div>
                          </div>
                        </AniLink>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>

            {/*<section>
              <h3>Current Ventures</h3>
              <div className="grid-frame">
                <div className="grid-block small-up-1 medium-up-2 large-up-3">
                  {ventures}
                </div>
              </div>
              {/*{companiesSectionTitle &&
                <h2>{companiesSectionTitle}</h2>
              }

              <h3>Clients</h3>
              <div className="grid-frame">
                <div className="grid-block small-up-1 medium-up-2 large-up-4">

                  {clients}
                </div>
              </div>
              <h3>Agencies</h3>
              <div className="grid-frame">
                <div className="grid-block small-up-1 medium-up-2 large-up-4">
                  {agencies}
                </div>
              </div>

            </section>*/}
            <SocialLinks />
          </div>
        </div>
        <FilterBlue />
        <Footer />
      </main>
    </Layout>
  )
}

export default ProjectsPage
